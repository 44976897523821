<template>

<div style="max-width: 800px">

  <div class="card-box">
    <h4>Top Domains</h4>
    <p>View the top domains we've been seeing in messages</p>
    <CButton @click="loadPath('domain-reputation/top-domains')" color="info" class="card-box-button">
      Top Domains
    </CButton>
  </div>

  <div class="card-box">
    <h4>Domain Lookup</h4>
    <p>View the data we use to generate a domains reputation</p>
    <CButton @click="loadPath('domain-reputation/domain-lookup')" color="info" class="card-box-button">
      Domain Lookup
    </CButton>
  </div>

  <div class="card-box">
    <h4>Whois</h4>
    <p>View the whois data for a domain</p>
    <CButton @click="loadPath('domain-reputation/whois')" color="info" class="card-box-button">
      Whois
    </CButton>
  </div>


</div>
</template>

<script>
export default {
  name: 'Domain-Reputation',
  methods: {
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
  }
}
</script>
